import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TeamCard from '../components/OurTeam/TeamCard'
import NavBackdrop from '../components/utils/NavBackdrop'
import Page from '../templates/Page'

function OurTeam() {
    const [data,setData]=useState([]);
    useEffect(() => {
       const fetch=async()=>{
           axios.get('https://maxconnect.com.np/school_api/team').then(res=>{
               setData(res.data);
           })
       }
       fetch();
    }, [])
    return (
        <Page>
            <NavBackdrop img='https://images.pexels.com/photos/5428267/pexels-photo-5428267.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
            title="Our Team" from='Home' link="our_team" />
            <div className="container mx-auto px-10 my-24">
                <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-24">
                   {data.map(item=>(
                        <TeamCard 
                        img={item.img} 
                        name={item.name}
                        position={item.position} 
                        msg={item.msg} />
                   ))}
                    
                </div>
            </div>
        </Page>
    )
}

export default OurTeam
