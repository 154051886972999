import React, { useEffect, useState } from 'react'
import Page from '../templates/Page'
import {IoCallSharp, IoLocation} from 'react-icons/io5'
import {HiOutlineMail} from 'react-icons/hi'
import axios from 'axios'

function Contact() {
    const [data,setData]=useState([]);
    console.log(data)
    useEffect(() => {
        const fetch=async()=>{
            await axios.get('https://maxconnect.com.np/school_api/school_info').then(res=>{
                setData([res.data[0]])
            })
        }
        fetch();
    }, [])
    return (
        <Page>
            {data.map(item=>(
                <div className="lg:h-screen grid grid-cols-1 lg:grid-cols-2">
                <iframe src={item.map} className='w-full h-full'  allowfullscreen="" loading="lazy"></iframe>
                <div className='px-10 bg-gray-100'>
                    <h1 className="text-5xl text-gray-700 mt-32">
                        CONTACT US
                    </h1>
                    <h3 className="font-semibold mt-4 text-gray-600">
                    We will be happy to answer your questions
                    </h3>
                    <div className="my-10">
                        <div className="flex space-x-4 my-10">
                            <IoLocation className='text-4xl text-primary mt-1 cursor-pointer hover:text-green-800 transition duration-400' />
                            <div>
                                <h4 className='text-gray-800'>Location</h4>
                                <p className="mt-1 text-gray-800">{item.location}</p>
                            </div>
                        </div>
                        <div className="flex space-x-4 my-10">
                            <HiOutlineMail className='text-4xl text-primary mt-1 cursor-pointer hover:text-green-800 transition duration-400' />
                            <div>
                                <h4 className='text-gray-800'>Email</h4>
                                <p className="mt-1 text-gray-800">{item.email}</p>
                            </div>
                        </div>
                        <div className="flex space-x-4 my-10">
                            <IoCallSharp className='text-4xl text-primary mt-1 cursor-pointer hover:text-green-800 transition duration-400' />
                            <div>
                                <h4 className='text-gray-800'>Phone</h4>
                                <p className="mt-1 text-gray-800">{item.phone}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            ))}
        </Page>
    )
}

export default Contact
