import axios from 'axios';
import React, { useEffect, useState } from 'react'
import NoticeCard from '../components/Notice/NoticeCard';

import Page from '../templates/Page'

function Notice() {
    const [data, setData] = useState([]);
  console.log(data)
  useEffect(() => {
    const fetch = async () => {
      await axios.get("https://maxconnect.com.np/school_api/notice").then(res => {
        setData(res.data);
      });
    };
    fetch();
  }, []);
    return (
        <Page>
            <div className="container lg:bg-gray-100 mx-auto px-4 lg:px-44 lg:py-10">
                {/* heading section  */}
                <div className="bg-white lg:p-20 lg:m-10">
                <div className="">
                    <h2>NOTICES</h2>
                    <div className="mt-4 flex space-x-4 items-center">
                    <input type="date" name="" id="" className='border p-2 rounded shadow' />
                    <button className="btn-primary rounded">Search</button>
                    </div>
                </div>
                <div className="">
                    {data.map(item=>(
                    <NoticeCard key={item.key} id={item.id} title={item.title} date={item.date} />
                    ))}
                </div>
                </div>
            </div>
            
        </Page>
    )
}

export default Notice
