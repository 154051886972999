import React, { useState,useEffect } from "react";
import Page from "../templates/Page"; // Import Swiper React components

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import TeamCard from "../components/OurTeam/TeamCard";
import { Link } from "react-router-dom";
import axios from "axios";

const AutoplaySlider = withAutoplay(AwesomeSlider);
function Home() {
  const [sliderdata, setSliderdata] = useState([]);
  console.log(sliderdata);
  const [aboutIntroData, setAboutIntroData] = useState([]);
  const [msg, SetMsg] = useState([]);
  const [team, setTeam] = useState([]);
  const slider = axios.get("https://maxconnect.com.np/school_api/slider");
  const aboutIntro = axios.get("https://maxconnect.com.np/school_api/about_intro");
  const message = axios.get("https://maxconnect.com.np/school_api/principle_msg");
  const team_member = axios.get("https://maxconnect.com.np/school_api/team");

 useEffect(() => {
  try {
    const fetch = async () => {
      await axios.all([slider, aboutIntro, message, team_member]).then(
        axios.spread((...response) => {
          setSliderdata(response[0].data);
          console.log(response[0].data);
          setAboutIntroData(response[1].data);
          SetMsg(response[2].data);
          setTeam(response[3].data);
        }),
      );
    };
    fetch();
  } catch (error) {
    console.error(error)
  }
 }, [])

  return (
    <Page>
      {/* slider area starts  */}

      <div className='h-80vh'>
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false} // should stop playing on user interaction
          interval={5000}
          className='h-full slider'
          
        >
          {sliderdata.map((item) => (
            <div key={item.id} className='w-full h-full'>
              <img
                src={item.img}
                className='w-full object-cover'
                alt=''
                srcset=''
              />
              <div className='lg:bg-gradient-to-t from-white absolute w-full h-full top-0'></div>
              <div className='absolute z-10 top-44 p-4 text-center lg:text-left lg:top-36 left-4 lg:left-36 w-80 lg:w-96 bg-white md:p-8 bg-opacity-70 rounded-md'>
                <h1 className='text-lg'>{item.title}</h1>
                <p className='mt-4 hidden md:block'>{item.description}</p>
                <Link to='/'>
                  <button className='mt-8 btn-primary rounded-full hidden lg:block'>
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </AutoplaySlider>
      </div>

      {/* slider area ends  */}
      <div className='container mx-auto lg:px-10'>
        {/* general intro starts  */}
        {aboutIntroData.map(item=>(
          <div className='lg:grid md:grid-cols-2 lg:gap-44 my-24 px-4 lg:px-0'>
          <img
            src={item.img}
            alt=''
            srcset=''
          />
          <div className=''>
            <p className='text-primary mt-10'>About Us</p>
            <h2 className='mt-2'>{item.title}</h2>
            <p className='mt-4 leading-relaxed tracking-wider'>
              {item.description}
            </p>
            <Link to='/contact'>
            <button className='btn-primary mt-10 rounded-full'>
              Contact Us
            </button>
            </Link>
          </div>
        </div>
        ))}
        {/* general intro ends  */}

        {/* message from principle  */}
        {msg.map(item=>(
          <div className='bg-gray-100 '>
          <div className='container mx-auto px-4 lg:px-10 text-center py-24'>
            <img
              src={item.img}
              className='w-24 h-24 rounded-full object-cover mx-auto shadow-xl border-1'
              alt=''
              srcset=''
            />
            <h1 className='mt-4'>Message from Principle</h1>
            <div className='mt-4'>
              <p className='text-lg'>{item.name}</p>
            </div>

            <div className='my-10 lg:m-24 card rounded-lg text-center'>
              <p className='p-2 md:px-24 md:py-10'>
               {item.msg}
              </p>
            </div>
          </div>
        </div>
        ))}
        
        {/* <div className='my-24'>
          <div className='text-center'>
            <h1 className='text-center mb-5'>Our Team</h1>
            <Link to='/our_team' className='btn-primary rounded-full'>
              View All
            </Link>
          </div>

          <div className='container mx-auto px-10 my-24'>
            <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-24'>
              {team.map(item=>(
                <TeamCard
                img={item.img}
                name={item.name}
                position={item.position}
                msg={item.msg}
              />
              ))}
              
            </div>
          </div>
        </div> */}

        <div className='my-10 px-4 lg:px-0'>
          <div className='bg-gray-100 p-4 lg:p-10'>
            <div className='mb-10'>
              <h1>Want to clear out some queries ??</h1>
              <Link to='/contact'>
                <button className='btn-primary rounded-full mt-5'>
                  Contact Us
                </button>
              </Link>
            </div>
            {/* <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3524.3580324916115!2d83.52181851454387!3d27.952319321291593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399679e6917d4635%3A0x86fa98b703bf8e2b!2sAlamdevi%20Syangja!5e0!3m2!1sen!2snp!4v1632225259867!5m2!1sen!2snp'
              className='w-full h-full'
              allowfullscreen=''
              loading='lazy'
            ></iframe> */}
          </div>
          {/* <div className="bg-black bg-opacity-50 w-full h-full p-20 absolute">
            <div className="text-white">
                <div className="flex justify-between">
                    <h1>
                    Want to clear out some queries
                    </h1>
                    <button className='btn-primary'>Contact Us</button>
                </div>
            </div>
        </div> */}
        </div>
      </div>
    </Page>
  );
}

export default Home;
