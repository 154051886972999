import React, { useEffect, useState } from 'react'
import NavBackdrop from '../components/utils/NavBackdrop'
import Page from '../templates/Page'
import {AiOutlineClose} from 'react-icons/ai'
import axios from 'axios';

function Gallery() {
    
    const [data,setData]=useState([]);
    const[modal,setModal]=useState(false);
    const[tempImgSrc,setTempImgSrc]=useState('');
    const getImg=(imgSrc)=>{
         setTempImgSrc(imgSrc);
         console.log("img",imgSrc)
         setModal(true);  
    }
    useEffect(() => {
        try {
            const fetch=async()=>{
                axios.get('https://maxconnect.com.np/school_api/gallery').then(res=>{
                    setData(res.data);
                })
            }
            fetch();
        } catch (error) {
            console.error(error)
        }
    }, [])
    return (
        <Page>
            <NavBackdrop img='https://images.pexels.com/photos/20967/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
            title='Gallery' from='Home' link="gallery" />

            {/* <div className="my-14 text-center">
                <h3>
                    <span className='mr-3 cursor-pointer hover:opacity-60 transition'>Type 1 |</span>
                    <span className='mr-3 cursor-pointer hover:opacity-60 transition'>Type 2 |</span>
                    <span className='mr-3 cursor-pointer hover:opacity-60 transition'>Type 3 |</span>
                    <span className='mr-3 cursor-pointer hover:opacity-60 transition'>Type 4 |</span>
                    <span className='mr-3 cursor-pointer hover:opacity-60 transition'>Type 5 </span>
                </h3>
            </div> */}

            <div className="container mx-auto px-4 my-20">

                <div className="gallery">
                    {data.map((item,index)=>(
                        <div className="pics border shadow-xl" key={index} onClick={()=>getImg(item.img)}>
                            <img src={item.img} alt="" />
                            <p className="my-4 px-4 font-semibold">{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* modal starts  */}
            <div className={modal ? 'modal open':'modal'}>
                 <img src={tempImgSrc} alt="" />
                 <AiOutlineClose className="fixed text-white top-4 right-2 font-bold text-4xl cursor-pointer" onClick={()=>{setModal(false)}} />
            </div>
        </Page>
    )
}

export default Gallery
