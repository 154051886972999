import React, { useEffect, useState } from "react";
import MissionCard from "../components/About/MissionCard";
import ObjectiveCard from "../components/About/ObjectiveCard";
import VisionCard from "../components/About/VisionCard";
import NavBackdrop from "../components/utils/NavBackdrop";
import Page from "../templates/Page";
import axios from "axios";
import { Link } from "react-router-dom";

function About() {
  const [data, setData] = useState([]);
  const [vms,setVms]=useState([]);
  const [mission,setMission]=useState([]);
  const [objective,setObjective]=useState([]);
  const [pmsg,setPmsg]=useState([]);
  console.log(data);
  console.log("vms",vms);
  console.log("pmsg",pmsg);
  useEffect(() => {
    const fetch = async () => {
        try {
            const aboutIntro=axios.get('https://maxconnect.com.np/school_api/about_intro');
            const vms=axios.get('https://maxconnect.com.np/school_api/vms');
            const msg=axios.get('https://maxconnect.com.np/school_api/principle_msg'); 
            await axios.all([aboutIntro,vms,msg]).then(
                axios.spread((...response)=>{
                    setData(response[0].data);
                    setVms([response[1].data[0]]);
                    setMission([response[1].data[1]]);
                    setObjective([response[1].data[2]]);
                    setPmsg([response[2].data[0]]);
                })
            )
        } catch (error) {
            console.error(error)
        }
      
    };
    fetch();
  }, []);
  return (
    <Page>
      {/* image backdrop  */}
      <NavBackdrop
        img='https://images.pexels.com/photos/8927026/pexels-photo-8927026.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
        title='About Us'
        from='Home'
        link='about'
      />

      {/* about section container starts  */}
      <div className='container mx-auto px-10'>
        {/* general intro starts  */}
        {data.map((item) => (
          <div className='lg:grid md:grid-cols-2 lg:gap-44 my-24'>
            <img src={item.img} alt='' srcset='' className='h-full w-full object-cover' />
            <div className=''>
              <p className='text-primary mt-10'>About Us</p>
              <h2 className='mt-2'>{item.title}</h2>
              <p className='mt-4 leading-relaxed tracking-wider'>
                {item.description}
              </p>
              <Link to='/contact'>
                <button className='btn-primary mt-10 rounded-full'>
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        ))}
        {/* general intro ends  */}

        {/* vison mission section starts  */}
        <div className='my-24'>
          {/* vision card  */}
          {vms.map(item=>(
              <VisionCard vms={item.type} des={item.description} />
          ))}
          {/* mission card  */}
          {mission.map(item=>(
              <MissionCard vms={item.type} des={item.description} />
          ))}
          {/* objective card  */}
          {objective.map(item=>(
              <ObjectiveCard vms={item.type} des={item.description} />
          ))}
        </div>
        {/* vison mission section ends  */}
      </div>
      {/* about section container ends  */}

      {/* message from principle  */}
      {pmsg.map(item=>(
      <div className='bg-gray-100 '>
        <div className='container mx-auto px-10 text-center py-24'>
          <img
            src={item.img}
            className='w-24 h-24 rounded-full object-cover mx-auto shadow-xl border-1'
            alt=''
            srcset=''
          />
          <h1 className='mt-4'>Message from Principle</h1>
          <div className='mt-4'>
            <p className='text-lg'>{item.name}</p>
          </div>

          <div className='my-10 lg:m-24 card rounded-lg text-center'>
            <p className='md:px-24 md:py-10'>
              {item.msg}
            </p>
          </div>
        </div>
      </div>
      ))}
    </Page>
  );
}

export default About;
