import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Page from '../../templates/Page';

function NoticeDetails() {
    const[data,setData]=useState([])
    console.log(data)
    const {id}=useParams();
    useEffect(() => {
       const fetch=async()=>{
        await axios.get(`https://maxconnect.com.np/school_api/notice/${id}`).then(res=>{
            setData(res.data.abc)
        })
       }
       fetch();    
    }, [])
    return (
        
        <Page>
            <div className="container card mx-auto px-14 my-10">
                {data.map(item =>(
                    <div key={item.id}>
                        <h1 className="mt-8 px-10">
                        {item.title}
                    </h1>
                    <div dangerouslySetInnerHTML={{__html:item.description}} className='p-10'>

                    </div>
                    </div>
                ))}
            </div>
        </Page>

    )
}

export default NoticeDetails
