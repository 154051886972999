import React from 'react'
import { FaQuoteLeft } from 'react-icons/fa'
import { IoTriangle } from 'react-icons/io5'
import Modal from 'react-modal';
import {AiOutlineClose} from 'react-icons/ai'


function MsgCard({img,name,position,msg}) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalMsg,setModalMsg]=React.useState('');
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: '4rem',
          bottom: '2rem',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
      function openModal(data) {
        setIsOpen(true);
        setModalMsg(data)
      }
      function closeModal() {
        setIsOpen(false);
      }
    return (
        <div>
            <div className="card relative">
                <p className='flex p-2 lg:p-10'>
                    <span><FaQuoteLeft className='text-red-800 mr-4 text-xl' /></span>
                    <div>
                    <span className='mt-4 line-clamp-4'>{msg}</span>
                    <p className="text-blue-700 font-semibold mt-2 cursor-pointer" onClick={()=>{openModal(msg)}}>Read More</p>
                    </div>
                </p>
            </div>
            <IoTriangle className='text-3xl transform -rotate-180 ml-10 text-gray-200' />
            <div className="my-5 ml-5 flex space-x-6 items-center">
                <img src={img} alt={name} className='w-16 h-16 rounded-full object-cover border shadow-xl' />
                <div className="">
                    <h3>{name}</h3>
                    <p>{position}</p>
                </div>
            </div>

            <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal}>
            <AiOutlineClose className='text-xl lg:text-3xl' />
        </button>
        <div className='text-center lg:m-10'>
            <p className='text-lg'>{modalMsg}</p>
        </div>
        
      </Modal>
        </div>
    )
}

export default MsgCard
