import React from 'react'
import Footer from '../components/utils/Footer'
import Nav from '../nav/Nav'

function Page( props ) {
    return (
        <div>
            <Nav />
            {props.children}
            {/* footer  */}
            <Footer />
        </div>
    )
}

export default Page
