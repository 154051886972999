import React from 'react'

function MissionCard({vms,des}) {
    return (
        <div className='my-24 grid lg:grid-cols-3 lg:gap-24 bg-yellow-100'>
            <div className="col-span-2 card lg:row-start-1" dangerouslySetInnerHTML={{__html:des}}>
            
        </div>
        <div className="text-left row-start-1 p-10 lg:p-0">
            <h1 className='mt-16 uppercase'>{vms}</h1>
        </div>
        
    </div>
    )
}

export default MissionCard
