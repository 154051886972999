import React from 'react'
import { BiTimeFive } from 'react-icons/bi'
import {FaRegFilePdf} from 'react-icons/fa'
import { useHistory } from 'react-router'

function NoticeCard({id,title,date,file}) {
    const history=useHistory();
    const handleLink=(notice_id)=>{
        history.push(`./notice/${notice_id}`)
    }
    return (
        <div className='text-gray-600 py-4'>
            <h2>{title}</h2>
            <p className="my-2 flex items-center"><BiTimeFive className='mr-2' /> {date}</p>
            {file ? <div className='flex items-center  cursor-pointer'><FaRegFilePdf className="mr-2 text-red-500" /> <p>{file}</p> </div> : ''}
            <button className='border shadow mt-4 rounded-full py-1 px-3' onClick={()=>{handleLink(id)}}>View Details</button>
            <hr className='border mt-4' />
        </div>
    )
}

export default NoticeCard