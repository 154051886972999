import { Route, Link, HashRouter } from "react-router-dom";
import Home from "./pages/Home";
import './css/master.scss'
import About from "./pages/About";
import DirectorsMessage from "./pages/DirectorsMessage";
import Gallery from "./pages/Gallery";
import OurTeam from "./pages/OurTeam";
import Contact from "./pages/Contact";
import Notice from "./pages/Notice";
import NoticeDetails from "./components/Notice/NoticeDetails";
function App() {
  return (
    <HashRouter>
      <Route path='/' exact component={Home} />
      <Route path='/about' exact component={About} />
      <Route path='/director_message' exact component={DirectorsMessage} />
      <Route path='/gallery' exact component={Gallery} />
      <Route path='/our_team' exact component={OurTeam} />
      <Route path='/contact' exact component={Contact} />
      <Route path='/notice' exact component={Notice} />
      <Route path='/notice/:id' exact component={NoticeDetails} />
    </HashRouter>
  );
}

export default App;
