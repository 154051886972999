import React, {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import NavItems from '../../nav/Navigations.json';
import {IoCallSharp, IoLocation} from 'react-icons/io5'
import {HiOutlineMail} from 'react-icons/hi'
import {BiTime} from 'react-icons/bi'
import axios from 'axios';


function Footer() {
    const [data,setData]=useState([]);
    const[des,setDes]=useState([]);
    console.log(des)
    useEffect(() => {
      try {
        const fetch=async()=>{
            const datas=axios.get('https://maxconnect.com.np/school_api/school_info');
            const about=axios.get('https://maxconnect.com.np/school_api/about_intro');
            await axios.all([datas,about]).then(axios.spread((...response)=>{
                setData([response[0].data[0]])
                setDes([response[1].data[0]])
            }))           
        }
        fetch();
      } catch (error) {
          console.error(error)
      }
    }, [])
    return (
        <div className='bg-primary'>
            <div className="container mx-auto px-10 py-16 text-white">
                {data.map(item=>(
                    <div key={item.id} className="grid lg:grid-cols-3 gap-8 lg:gap-16">
                    {/* left section starts  */}
                    <div className="">
                        <img src={item.img}
                        className='w-16 h-16 object-cover' alt="" srcset="" />
                        {des.map(item=>(
                            <div>
                                <p className="my-10 leading-relaxed line-clamp-4">                           
                            {item.description}                            
                            </p>
                            <Link to='/about' className='border p-2 rounded-md'>Read More</Link>
                            </div>

                        ))}
                    </div>
                    {/* left section ends  */}

                    {/* mid section starts  */}
                    <div className="">
                    <h3>Quick Links</h3>
                    <div className="flex flex-col space-y-6 mt-10">
                        {NavItems.map((item)=>(
                            <Link to={item.to} className='hover:opacity-80 transitions'>{item.title}</Link>
                        ))}
                    </div>
                    </div>
                    {/* mid section ends  */}

                    {/* right section starts  */}
                    <div>
                    <h3>Contact</h3>
                    <div className="mt-10 flex items-center">
                        <IoLocation className='font-white mr-2' /> <p>{item.location}</p>
                    </div>
                    <div className="mt-8 flex items-center">
                        <IoCallSharp className='font-white mr-2' /> <p>{item.phone}</p>
                    </div>
                    <div className="mt-8 flex items-center">
                        <HiOutlineMail className='font-white mr-2' /> <p>{item.email}</p>
                    </div>
                    <div className="mt-8 flex">
                        <BiTime className='font-white mt-2 mr-2' /> <p>Sun-Fri: 10:00 AM - 04:00 PM <br />Sat: Closed</p>
                    </div>
                    </div>
                    {/* right section ends  */}
                </div>
                ))}
            </div>
        </div>
    )
}

export default Footer
