import React from 'react'

function TeamCard({img,name,position,msg}) {
    return (
        <div className='my-8 lg:my-0'>
            <img src={img} alt={name} srcset="" className='w-full h-52 object-cover shadow' />
            <h2 className="mt-3">
                {name}
            </h2>
            <h3 className="mt-3 text-primary">
                {position}
            </h3>
            <p className="hidden lg:block mt-3 text-gray-400">
                {msg}
            </p>
        </div>
    )
}

export default TeamCard
