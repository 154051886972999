import React from 'react'

function NavBackdrop({img,title,from,link}) {
    return (
        <>
        {/* <img src={img} alt={title} className='w-full h-screen object-cover -z-10' srcset="" /> */}
        <div style={{backgroundImage:`url(${img})`}} className='backDrop_bg h-screen'>
        <div className="bg-black w-full h-screen absolute top-22 z-0 bg-opacity-40"></div>
        <div className='container mx-auto px-10 absolute top-80 text-center z-10 text-white'>
            <h1 className='text-6xl'>{title}</h1>
            <div className="items-center my-4">
                <h2>{from}  / {link} </h2>
            </div>
        </div>
        </div>
        </>
        
    )
}

export default NavBackdrop
