import axios from 'axios';
import React, { useEffect, useState } from 'react'
import MsgCard from '../components/DirectorMessage/MsgCard'
import NavBackdrop from '../components/utils/NavBackdrop'
import Page from '../templates/Page'


function DirectorsMessage() {
    const [data,setData]=useState([]);
    
    useEffect(() => {
        const fetch=async()=>{
            axios.get('https://maxconnect.com.np/school_api/director_msg').then(res=>{
                setData(res.data)
            })
        }
        fetch();
    }, [])
    return (
        <Page>
            <NavBackdrop img='https://images.pexels.com/photos/5717556/pexels-photo-5717556.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260' title='Director Message' from='Home' link='director_message' />
            <div className="md:grid md:grid-cols-2 md:gap-14 lg:gap-20 my-24 mx-auto px-4 lg:mx-32">
                {data.map(item=>(
                <MsgCard key={item.id} img={item.img} name={item.name} position={item.position} msg={item.msg}/>
                ))}
                
            </div>

            
        </Page>
    )
}

export default DirectorsMessage
