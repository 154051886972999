import React from 'react'

function ObjectiveCard({vms,des}) {
    return (
        <div className='my-24 lg:grid lg:grid-cols-3 lg:gap-24 bg-green-100'>
            <div className="lg:text-right">
                <h1 className='mt-16 p-10 lg:p-0 uppercase'>{vms}</h1>
            </div>
        <div className="col-span-2 card" dangerouslySetInnerHTML={{__html:des}}>
            
        </div>
    </div>
    )
}

export default ObjectiveCard
